import { Image, Input, message, Modal, Typography } from 'antd';

import { get, isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import * as yup from 'yup';
import { Links } from '../../../../types';
import { socialMediaIconsObject } from '../../../constants/icons.constant';
import { useAuth } from '../../../contexts/auth.context';
import {
  Enum_Display_Location_Enum,
  GetUserLinksByOwnerIdAndLocationQuery,
  useCreateUserLinkByUserIdMutation,
  useEditUserLinkMutation,
} from '../../../graphql/user-links.generated';
import { getFileBasePath } from '../../../helpers/storage.helper';
import { IconsModal } from './icons-modal';

const schema = yup.object({
  link_name: yup.string().required().label('Name'),
  link_url: yup.string().url().required().label('URL'),
});

type linkToEditProps = GetUserLinksByOwnerIdAndLocationQuery['user_links'][0];
interface CreateUserLinksProps {
  showAddLink: boolean;
  setShowAddLink: (showAddLink: boolean) => void;
  links?: Links[];
  setLinks?: (links: Links[]) => void;
  display_location: Enum_Display_Location_Enum;
  linkToEdit?: linkToEditProps | undefined;
  setLinkToEdit?: (linkToEdit: linkToEditProps | undefined) => void;
}

export function CreateUserLinks({
  showAddLink,
  setShowAddLink,
  display_location,
  linkToEdit,
  setLinkToEdit,
}: CreateUserLinksProps) {
  const [name, setName] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();

  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<
    { storage_object_id: string; localFileURL: string } | undefined
  >();
  const [icon, setIcon] = useState<
    { name: string; component: JSX.Element } | undefined
  >();

  const { accountId } = useAuth();
  const [editUserLink, { loading: Loading }] = useEditUserLinkMutation({
    refetchQueries: ['getUserLinksByOwnerIdAndLocation'],
  });
  const [createUserLinkByUserId, { loading }] =
    useCreateUserLinkByUserIdMutation({
      refetchQueries: ['getUserLinksByOwnerIdAndLocation'],
    });

  useEffect(() => {
    if (linkToEdit) {
      setName(linkToEdit?.link_name);
      setUrl(linkToEdit?.link_url);
    }
  }, [linkToEdit]);

  const imagePath = getFileBasePath(linkToEdit?.icon_object?.storage_url);
  const iconName =
    linkToEdit?.icon_preset as keyof typeof socialMediaIconsObject;

  const onOkClick = async () => {
    try {
      await schema.validate(
        {
          link_name: name,
          link_url: url,
          icon_preset: icon?.name,
          owned_by: accountId,
          display_location,
        },
        { abortEarly: true }
      );
      linkToEdit
        ? ReactGA.gtag('event', 'Edit User Links', {
            event_category: 'Edit User Links',
            event_label: 'Edit Link',
            items: [
              {
                icon_object_id: photoUrl?.storage_object_id
                  ? photoUrl?.storage_object_id
                  : linkToEdit?.icon_object?.id,
                icon_preset: icon ? icon.name : linkToEdit?.icon_preset,
                id: linkToEdit?.id,
                link_name: name,
                link_url: url,
                owned_by: accountId,
                display_location,
              },
            ],
          })
        : ReactGA.gtag('event', 'Add User Links', {
            event_category: 'Add User Links',
            event_label: 'Add Link',
            items: [
              {
                link_name: name,
                link_url: url,
                icon_preset: icon?.name,
                icon_object_id: photoUrl?.storage_object_id,
                owned_by: accountId,
                display_location,
              },
            ],
          });
      linkToEdit
        ? await editUserLink({
            variables: {
              icon_object_id: photoUrl?.storage_object_id
                ? photoUrl?.storage_object_id
                : linkToEdit?.icon_object?.id,
              icon_preset: icon ? icon.name : linkToEdit?.icon_preset,
              id: linkToEdit?.id,
              link_name: name,
              link_url: url,
              owned_by: accountId,
              display_location,
            },
          })
        : await createUserLinkByUserId({
            variables: {
              object: {
                link_name: name,
                link_url: url,
                icon_preset: icon?.name,
                icon_object_id: photoUrl?.storage_object_id,
                owned_by: accountId,
                display_location,
              },
            },
          });

      setIcon(undefined);
      setShowAddLink(false);
      setPhotoUrl(undefined);
      setName(undefined);
      setUrl(undefined);
      setLinkToEdit && setLinkToEdit(undefined);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        message.error(get(error, 'message'));
      }
    }
  };
  const Icon = icon?.component || socialMediaIconsObject[iconName];

  return (
    <Modal
      destroyOnClose
      title="Add Link"
      visible={showAddLink}
      onCancel={() => {
        setIcon(undefined);
        setShowAddLink(false);
        setPhotoUrl(undefined);
        setName(undefined);
        setUrl(undefined);
        setLinkToEdit && setLinkToEdit(undefined);
      }}
      okType="primary"
      okButtonProps={loading || Loading ? { disabled: true } : {}}
      onOk={onOkClick}
    >
      <div className="grid grid-cols-2 gap-3 place-items-center">
        <div
          className="cursor-pointer overflow-hidden flex justify-center items-center hover:scale-105 duration-200 ease-linear
          rounded-full w-32 h-32 border border-dashed border-gray-600"
          onClick={() => setShowIcons(true)}
        >
          {!isUndefined(imagePath) ? (
            <Image src={imagePath} preview={false} alt={imagePath} />
          ) : icon || iconName ? (
            <Icon className="icon" />
          ) : null}
          {photoUrl && (
            <Image
              src={photoUrl?.localFileURL}
              preview={false}
              className="h-[8rem] rounded-full"
              alt={icon?.name}
            />
          )}
          {!icon && !photoUrl && !linkToEdit && (
            <Typography.Text className="text-base w-full p-5 text-center">
              Click to select an icon
            </Typography.Text>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Input
            type="text"
            className="rounded-md custom-input-border"
            placeholder="Name of the link custom-input-border"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="url"
            className="rounded-md custom-input-border"
            placeholder="URL of the link custom-input-border"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
      </div>
      {showIcons && (
        <IconsModal
          showIcons={showIcons}
          setShowIcons={setShowIcons}
          setIcon={setIcon}
          photoUrl={photoUrl}
          setPhotoUrl={setPhotoUrl}
        />
      )}
    </Modal>
  );
}
