import { Form, Input } from 'antd';
import { useProfileEdit } from '../../../contexts/profile-edit.context';

export function ProfileForm() {
  const { setFormData, formData } = useProfileEdit();

  return (
    <Form
      layout="vertical"
      size="middle"
      initialValues={formData}
      onValuesChange={(_updated, allValues) => setFormData(allValues)}
    >
      <Form.Item
        name="username"
        label="User Name"
        rules={[
          { required: true, message: 'User name is required' },
          {
            min: 4,
            message: 'User name must contain at least 4 characters',
          },
          {
            pattern: /^[a-z0-9_-]+$/,
            message: 'small case alphabets and numbers are only allowed',
          },
        ]}
      >
        <Input prefix="hoji.xyz/" />
      </Form.Item>
      <Form.Item
        name="display_name"
        label="Display Name"
        rules={[
          { required: true, message: 'Display Name is required' },
          {
            min: 4,
            message: 'Display Name must contain at least 4 characters',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="bio" label="Bio (optional)">
        <Input.TextArea maxLength={100} showCount />
      </Form.Item>
    </Form>
  );
}
