import { Enum_Storage_Object_Type_Enum } from '@hoji/shared-backend';
import axios from 'axios';
import { last } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../contexts/auth.context';
import { useClientSDK } from '../contexts/sdk.context';
export const useCreateStorageObject = () => {
  const { accountId } = useAuth();
  const sdk = useClientSDK();

  const uploadFile = async (file_path: string, file: File) => {
    const uploadURLQuery = await sdk.getUploadURL({
      object: { file_path: file_path },
    });

    await axios.put(
      uploadURLQuery.get_upload_url?.presigned_url as string,
      file,
      { headers: { 'Content-Type': file.type } }
    );

    const storageQuery = await sdk.createStorageObject({
      object: {
        created_by: accountId,
        storage_url: file_path,
        object_type: Enum_Storage_Object_Type_Enum.Image,
      },
    });

    return { storage_object_id: storageQuery.insert_storage_objects_one?.id };
  };

  const uploadFileToFolder = async (file_path: string, file: File) => {
    const { storage_object_id } = await uploadFile(
      `${file_path}/${uuidv4()}.${last(file?.name.split('.'))}`,
      file
    );

    const localFileURL = URL.createObjectURL(file);

    return { storage_object_id, localFileURL };
  };

  return { uploadFile, uploadFileToFolder };
};
