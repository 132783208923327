import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useState } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import {
  Enum_Display_Location_Enum,
  useGetUserLinksByOwnerIdAndLocationQuery,
} from '../../../graphql/user-links.generated';
import { ViewRoundLinks } from '../../storefront/links/view-round-links';
import { Links } from './../../../../types';
import { CreateUserLinks } from './create-user-links';

export function AddRoundLinks() {
  const [showAddLink, setShowAddLink] = useState<boolean>(false);
  const [links, setLinks] = useState<Links[]>([]);
  const { accountId } = useAuth();

  const { data } = useGetUserLinksByOwnerIdAndLocationQuery({
    variables: {
      owned_by: accountId,
      display_location: Enum_Display_Location_Enum.ProfileCircleLinks,
    },
    skip: !accountId,
  });

  return (
    <div className="flex gap-3 justify-center items-center">
      <ViewRoundLinks links={data?.user_links} editMode={true} />
      <PlusOutlined
        onClick={() =>
          data && data?.user_links?.length < 5
            ? setShowAddLink(true)
            : message.error('You can only add 5 links')
        }
        className="px-2 mr-[10px] pt-2 mt-5 rounded-full text-2xl border-solid border-2 border-gray-400 hover:scale-105 transition-transform ease-linear cursor-pointer"
      />
      <CreateUserLinks
        display_location={Enum_Display_Location_Enum.ProfileCircleLinks}
        links={links}
        setLinks={setLinks}
        showAddLink={showAddLink}
        setShowAddLink={setShowAddLink}
      />
    </div>
  );
}
