import { GlobalOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Image, Tooltip } from 'antd';
import Link from 'next/link';
import { useState } from 'react';
import { socialMediaIconsObject } from '../../../constants/icons.constant';
import {
  GetUserLinksByOwnerIdAndLocationQuery,
  useDeleteUserLinkByIdMutation,
} from '../../../graphql/user-links.generated';
import { getFileBasePath } from '../../../helpers/storage.helper';
import { Enum_Display_Location_Enum } from '../../nfts/user-nfts.generated';
import { CreateUserLinks } from '../../settings/profile/create-user-links';

interface ViewRoundLinksProps {
  links: GetUserLinksByOwnerIdAndLocationQuery['user_links'] | undefined;
  editMode?: boolean;
}

export function ViewRoundLinks({ links, editMode }: ViewRoundLinksProps) {
  const [showAddLink, setShowAddLink] = useState<boolean>(false);
  const [linksToEdit, setLinksToEdit] = useState<
    GetUserLinksByOwnerIdAndLocationQuery['user_links'][0] | undefined
  >();
  const [deleteUserLinkByUserId] = useDeleteUserLinkByIdMutation({
    refetchQueries: ['getUserLinksByOwnerIdAndLocation'],
  });

  const LinkComponent = ({ link, imagePath, iconName, iconType }) => {
    const Icon = socialMediaIconsObject[iconName];
    return iconType === 'image' ? (
      <Image
        src={imagePath}
        preview={false}
        className="w-10 h-10 rounded-full object-cover"
        alt={link?.link_name}
      />
    ) : (
      <Icon className="w-10 h-10 rounded-full object-cover icon" />
    );
  };

  return (
    <div
      className={
        links
          ? 'flex sm:flex-row gap-3 items-center'
          : 'flex gap-3 items-center'
      }
    >
      {links?.map((link, key) => {
        const iconType = link?.icon_object ? 'image' : 'icon';

        const imagePath = link?.icon_object?.storage_url
          ? getFileBasePath(link?.icon_object?.storage_url)
          : undefined;

        const iconName =
          link?.icon_preset as keyof typeof socialMediaIconsObject;
        return (
          <Tooltip title={link?.link_name} placement="bottom" key={key}>
            <div
              className={`rounded-full pt-5 cursor-pointer hover:scale-110 transition-transform ease-linear mr-2`}
            >
              {editMode && (
                <div className="flex items-center justify-end">
                  <MinusCircleFilled
                    onClick={() => {
                      deleteUserLinkByUserId({ variables: { id: link.id } });
                    }}
                    className="cursor-pointer text-lg"
                  />
                </div>
              )}
              {editMode ? (
                imagePath || iconName ? (
                  <div
                    onClick={() => {
                      setLinksToEdit(link);
                      setShowAddLink(true);
                    }}
                  >
                    <LinkComponent
                      link={link}
                      imagePath={imagePath}
                      iconName={iconName}
                      iconType={iconType}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setLinksToEdit(link);
                      setShowAddLink(true);
                    }}
                  >
                    <GlobalOutlined className=" pt-1.5 text-3xl w-10 h-10 rounded-full object-cover" />
                  </div>
                )
              ) : imagePath || iconName ? (
                <Link href={link?.link_url} passHref>
                  <a target={'_blank'} rel="noopener noreferrer">
                    <LinkComponent
                      link={link}
                      imagePath={imagePath}
                      iconName={iconName}
                      iconType={iconType}
                    />
                  </a>
                </Link>
              ) : (
                <Link href={link?.link_url} passHref>
                  <a target={'_blank'} rel="noopener noreferrer">
                    <GlobalOutlined className=" pt-1.5 text-3xl w-10 h-10 rounded-full object-cover icon" />
                  </a>
                </Link>
              )}
            </div>
          </Tooltip>
        );
      })}
      <CreateUserLinks
        display_location={Enum_Display_Location_Enum.ProfileCircleLinks}
        showAddLink={showAddLink}
        setShowAddLink={setShowAddLink}
        linkToEdit={linksToEdit}
        setLinkToEdit={setLinksToEdit}
      />
    </div>
  );
}
