import { MarketPlaceEnum, SaleKindEnum } from '@hoji/eth-networks';
import { Button, Grid, message, Switch, Typography } from 'antd';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { NFT } from '../../../types';
import { useAuth } from '../../contexts/auth.context';
import { getChainName } from '../../helpers/formatter.helper';
import { useStoreName } from '../../hooks/store-name.hook';
import {
  EthereumLogo,
  FoundationLogo,
  OpenSeaLogo,
} from '../icons/chain-icons';
import { NFTBuyPopUp } from './nft-transactions/nft-buy/nft-buy-pop-up';
import { NFTViewer } from './nft-viewer';
import {
  Enum_Nft_Type_Enum,
  useSaveUserLinkedNftTokenMutation,
} from './user-nfts.generated';
export interface NFTCardProps {
  nft: NFT;
  edit?: boolean;
  setDisableShowHide: React.Dispatch<React.SetStateAction<boolean>>;
  disableShowHide: boolean;
  showHideNFTDetails?: boolean;
  themesView?: boolean;
  type?: 'created' | 'owned';
  isLanding?: boolean;
  storeName?: string;
}

const { useBreakpoint } = Grid;

export function NFTCard({
  nft,
  edit,
  themesView,
  disableShowHide,
  setDisableShowHide,
  showHideNFTDetails,
  type = 'owned',
  isLanding = false,
  storeName,
}: NFTCardProps) {
  const { xs, lg } = useBreakpoint();
  const store = useStoreName();
  const [showBuyNftPopUp, setShowBuyNftPopUp] = useState(false);
  const { isLoggedIn, accountId } = useAuth();

  const isStoreOwner = useMemo(
    () => accountId === nft.owned_by && isLoggedIn,
    [accountId, nft, isLoggedIn]
  );
  const getMarketPlaceLogo: { [key: string]: JSX.Element } = {
    OPENSEA: (
      <OpenSeaLogo className="h-10 w-10 rounded-full shadow-inner p-1 sub-header" />
    ),
    FOUNDATION: (
      <FoundationLogo className="h-10 w-10 rounded-full shadow-inner p-1 sub-header" />
    ),
  };

  const [saveUserLinkedNftToken, { loading }] =
    useSaveUserLinkedNftTokenMutation();

  const onSwitchChange = async (checked: boolean) => {
    setDisableShowHide(true);
    ReactGA.event('Hide/Show NFT', {
      category: 'Show/Hide',
      action: 'show or hide NFT',
      label: `Clicked on show/hide NFT in ${type} NFTs`,
    });
    await saveUserLinkedNftToken({
      variables: {
        object: {
          token_address: nft.token_address,
          token_id: nft.token_id,
          owned_by: nft.owned_by,
          visible: !checked,
          wallet_provider_id: nft.wallet_provider_id,
          nft_type: type as Enum_Nft_Type_Enum,
        },
      },
    });

    setDisableShowHide(false);
    message.success("NFT's visibility updated successfully");
  };
  const handleByClick = async () => {
    ReactGA.gtag('event', 'Profile page buy', {
      event_category: 'Profile page buy',
      event_label: 'Click on buy in profile page',
      items: [
        {
          storename: storeName || store,
        },
      ],
    });
    setShowBuyNftPopUp(true);
  };

  const chain = getChainName(nft?.marketplace as string);
  const viewDetailsUrl = `/assets/${chain}/${nft?.token_address}/${
    nft?.token_id
  }?store=${store ?? storeName}`;

  const onViewClick = () => {
    if (typeof window !== 'undefined')
      localStorage.setItem('nftDetails', JSON.stringify(nft));
  };

  return (
    <>
      <div
        className={`${
          isLanding ? 'w-40' : 'md:w-64 w-40 '
        }rounded-xl shadow-2xl drop-shadow-md relative nft-card ${
          themesView || edit ? 'scale-75' : ''
        }`}
      >
        {edit && (
          <div className="flex justify-end gap-1 items-center absolute top-2 z-50 right-1 bg-white rounded-full p-1">
            <div>
              <Typography.Text className="font-medium text-xs ">
                Hide
              </Typography.Text>
            </div>
            <div>
              <Switch
                defaultChecked={!nft?.visible}
                onChange={onSwitchChange}
                disabled={disableShowHide}
                loading={loading}
                size="small"
              />
            </div>
          </div>
        )}
        {(nft?.current_price ||
          (nft.sale_type && nft.sale_type !== SaleKindEnum.None)) && (
          <div className="w-[60px] absolute top-2 left-2 z-[100]">
            {getMarketPlaceLogo[nft.marketplace as string]}
          </div>
        )}
        <div className="overflow-hidden bg-cardPrimary transition-transform ease-linear duration-500 rounded-xl">
          <Link href={viewDetailsUrl} passHref>
            <a target={'_blank'}>
              <div
                className="flex justify-center  items-center  hover:scale-125 transition-transform ease-linear duration-[250]"
                onClick={onViewClick}
              >
                <NFTViewer
                  nft={nft}
                  width={xs || isLanding ? 176 : 256}
                  height={
                    edit
                      ? lg
                        ? 180
                        : xs || isLanding
                        ? 130
                        : 150
                      : xs || isLanding
                      ? 150
                      : 256
                  }
                />
              </div>
            </a>
          </Link>
        </div>
        <div
          className={
            showHideNFTDetails || edit
              ? 'hidden'
              : 'flex flex-col justify-center p-2 pt-1  md:p-3'
          }
        >
          <div className="flex justify-between items-center">
            <Typography.Text strong className=" text-sm md:text-lg truncate">
              {nft?.name}
            </Typography.Text>
          </div>
          <div className="flex justify-between items-center">
            {nft.current_price && (
              <div className="flex justify-between items-center">
                <div className="flex justify-center items-center gap-1">
                  <EthereumLogo className="w-3" />
                  <Typography.Text className="font-medium  text-lg">
                    {nft.current_price}
                  </Typography.Text>
                </div>
              </div>
            )}
            {(nft.marketplace === MarketPlaceEnum.Foundation ||
              nft?.current_price) &&
            nft?.sale_type === SaleKindEnum.Buy ? (
              <Button
                type="primary"
                size={edit ? 'small' : xs || isLanding ? 'middle' : 'large'}
                className="rounded-md "
                onClick={handleByClick}
                disabled={isStoreOwner}
              >
                Buy Now
              </Button>
            ) : (
              <div className="w-full">
                <Link href={viewDetailsUrl} passHref>
                  <Button
                    type="primary"
                    size={edit ? 'small' : xs || isLanding ? 'middle' : 'large'}
                    className="rounded-md float-right w-20"
                    typeof="a"
                    target="_blank"
                    onClick={onViewClick}
                  >
                    View
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <NFTBuyPopUp
        setShowBuyNftPopUp={setShowBuyNftPopUp}
        showBuyNftPopUp={showBuyNftPopUp}
        nft={nft}
      />
    </>
  );
}
