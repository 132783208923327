import { TwitterCircleFilled } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import { Modal, Typography } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';

export function TwitterModal() {
  const {
    query: { new_account },
    replace,
    pathname,
  } = useRouter();
  const [showTwitter, { setFalse }] = useBoolean(new_account === 'true');

  const handleClose = () => {
    setFalse();
    replace(pathname, undefined, { shallow: true });
  };

  return (
    <Modal
      closable
      destroyOnClose
      visible={showTwitter}
      footer={null}
      title={
        <Typography.Title className="text-center" level={4}>
          Welcome to Hoji
        </Typography.Title>
      }
      onOk={handleClose}
      onCancel={handleClose}
    >
      <div className="flex gap-4 items-center">
        <Link href={'https://www.twitter.com'} passHref>
          <TwitterCircleFilled
            className="text-4xl text-sky-500"
            typeof="a"
            target={'_blank'}
            rel={'noopener noreferrer'}
          />
        </Link>
        <Typography className=" text-xl">
          Congratulations on creating your store!{' '}
          <span role="img" aria-label="image">
            🎉
          </span>
          <br />
          Spread the word!!{' '}
        </Typography>
      </div>
    </Modal>
  );
}
