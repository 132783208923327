import { ReloadOutlined } from '@ant-design/icons';
import { Button, notification, Spin, Tabs, Tooltip, Typography } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useIdleTimer } from 'react-idle-timer';
import { APP_ENVIRONMENT } from '../../../config';
import { useAuth } from '../../contexts/auth.context';
import { useClientSDK } from '../../contexts/sdk.context';
import {
  useGetUserByUserNameQuery,
  useGetUserLoadingFeedbackSubscription,
} from '../../graphql/users.generated';
import { useGetUserLinksByStore } from '../../hooks/get-user-links-by-store.hook';
import { useNft } from '../../hooks/nft-fetch.hook';
import { useStoreName } from '../../hooks/store-name.hook';
import { NFTCard } from '../nfts/nft-card';
import { NFTCardCollection } from '../nfts/nft-card-collections';
import {
  GetUserNftsQuery,
  useGetNftTokensCreatedSubscription,
  useGetNftTokensCreatedVisibleAggregateSubscription,
} from '../nfts/user-nfts.generated';
import { ViewButtonLinks } from '../storefront/links/view-button-links';
import { NoStoreView } from '../storefront/no-store-view';
import { ProfileContainer } from '../storefront/profile-container';
import { TwitterModal } from '../storefront/twitter-modal';

interface StoreFrontPageProps {
  themesView?: boolean;
  storeFront?: string | undefined;
}

export function StoreFrontPage({
  themesView,
  storeFront,
}: StoreFrontPageProps) {
  const { TabPane } = Tabs;
  const store = useStoreName();

  const { push } = useRouter();
  const { accountId: id, isLoggedIn } = useAuth();
  const { data: userQuery, loading: storeUserLoading } =
    useGetUserByUserNameQuery({
      variables: { username: storeFront || store + '' },
      skip: !storeFront && !store,
    });
  const { data, lastCardRef, loading } = useNft({
    store: storeFront ?? store,
  });
  const storeUser = useMemo(() => userQuery?.users[0], [userQuery]);
  const nfts: GetUserNftsQuery['user_nft_tokens'] = data.sort((a, b) =>
    (a?.sale_type as string)?.localeCompare(b?.sale_type as string)
  );

  const { data: loadingData } = useGetUserLoadingFeedbackSubscription({
    variables: {
      id: storeUser?.id,
    },
    skip: !storeUser,
  });

  const ethereumOwnedFetching =
    loadingData?.users_by_pk?.metadata?.ownedFetch['ethereum'];
  const tezosOwnedFetching =
    loadingData?.users_by_pk?.metadata?.ownedFetch['tezos'];
  const solanaOwnedFetching =
    loadingData?.users_by_pk?.metadata?.ownedFetch['solana'];
  const ethereumCreatedFetching =
    loadingData?.users_by_pk?.metadata?.createdFetch['ethereum'];
  const tezosCreatedFetching =
    loadingData?.users_by_pk?.metadata?.createdFetch['tezos'];
  const solanaCreatedFetching =
    loadingData?.users_by_pk?.metadata?.createdFetch['solana'];

  const fetchingOwnedNfts =
    ethereumOwnedFetching || tezosOwnedFetching || solanaOwnedFetching;
  const fetchingCreatedNfts =
    ethereumCreatedFetching || tezosCreatedFetching || solanaCreatedFetching;

  const { userLinks } = useGetUserLinksByStore(storeFront || (store as string));

  const [disableShowHide, setDisableShowHide] = useState(false);

  const { data: createdTokens } = useGetNftTokensCreatedSubscription({
    variables: { username: storeFront || store + '' },
    skip: !store,
  });

  const { data: createdTokensAggregate } =
    useGetNftTokensCreatedVisibleAggregateSubscription({
      variables: { username: storeFront || store + '' },
      skip: !store,
    });

  const [idleCount, setIdleCount] = useState<number>(0);

  const timeout = 5000;

  const sdk = useClientSDK();

  const handleRefreshNFT = async () => {
    try {
      if (store || storeFront) {
        await sdk.getNfts({ username: store || storeFront + '' });
        await sdk.refreshCreatedNFT({ username: store || storeFront + '' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onIdle = () => {
    setIdleCount(idleCount + 1);
    if (idleCount < 1 && storeUser?.id !== id && !isLoggedIn && !storeFront) {
      return notification.open({
        message: (
          <Typography.Text
            className="cursor-pointer text-lg font-semibolds"
            onClick={() => {
              ReactGA.gtag('event', 'clickClaim', {
                event_category: 'clickClaim',
                event_label: 'claim',
                items: [
                  {
                    storeUsername: storeFront || store,
                    walletConnected: '',
                  },
                ],
              });
              !id ? push('/login') : push(`/`);
            }}
          >
            Claim your own hoji.xyz page!
          </Typography.Text>
        ),
        className:
          'text-center border border-solid border-black rounded-lg hover:scale-110 cursor-pointer transition-transform duration-500 ease-linear',
        placement: 'bottom',
        duration: 20,
      });
    }
  };

  useIdleTimer({ timeout, onIdle });
  if ((!store && !storeFront) || storeUserLoading) {
    return (
      <>
        <div className="h-[90vh] w-full flex justify-center items-center">
          <Spin size="large" />
        </div>
      </>
    );
  } else if (!storeUser?.id && !storeUserLoading) {
    return <NoStoreView />;
  } else {
    return (
      <>
        <div
          className={`flex flex-col items-center justify-center store-body ${
            storeFront
              ? 'p-1 lg:p-0 lg:w-[400px] border border-solid rounded-md drop-shadow-lg shadow-xl'
              : ''
          }`}
        >
          <ProfileContainer storeFront={storeFront} />
          <div
            className={`flex flex-col justify-center items-center w-full md:w-10/12 my-5 rounded-b-md ${
              storeFront && 'min-h-[342px]'
            }`}
          >
            <Tabs
              defaultActiveKey="1"
              centered
              destroyInactiveTabPane
              tabBarExtraContent={
                APP_ENVIRONMENT === 'development' ? (
                  <Tooltip title="Refresh NFTs">
                    <Button
                      type="link"
                      onClick={handleRefreshNFT}
                      icon={<ReloadOutlined />}
                    />
                  </Tooltip>
                ) : null
              }
            >
              {!isEmpty(userLinks?.user_links) ? (
                <TabPane tab="Links" key="1">
                  <ViewButtonLinks
                    links={
                      themesView
                        ? userLinks?.user_links.slice(0, 4)
                        : userLinks?.user_links
                    }
                  />
                </TabPane>
              ) : null}
              {!isEmpty(createdTokens?.created_nft_tokens) &&
              createdTokensAggregate?.created_nft_tokens_aggregate.aggregate
                ?.count !== 0 ? (
                <TabPane tab="Created" key={2}>
                  {fetchingCreatedNfts &&
                    isEmpty(createdTokens?.created_nft_tokens) && (
                      <div className="w-full h-[80vh] flex flex-col gap-5 justify-center items-center text-center text-lg">
                        <span>
                          Indexing NFTs: Please wait while we load the NFTs.
                        </span>
                        <Spin size="large" />
                      </div>
                    )}
                  <div
                    className={
                      themesView
                        ? 'grid grid-cols-2 place-content-center '
                        : 'my-5 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-5 place-content-center'
                    }
                  >
                    {themesView
                      ? createdTokens?.created_nft_tokens
                          .slice(0, 4)
                          .map((nft) => {
                            return (
                              <div
                                key={nft.id}
                                className={
                                  nft?.visible
                                    ? 'place-self-center cursor-pointer relative'
                                    : 'hidden'
                                }
                              >
                                <NFTCard
                                  edit={false}
                                  themesView={themesView}
                                  nft={nft}
                                  key={`${nft.token_id} + ${nft.token_address}`}
                                  disableShowHide={disableShowHide}
                                  setDisableShowHide={setDisableShowHide}
                                  showHideNFTDetails={false}
                                  isLanding={!isUndefined(storeFront)}
                                  storeName={storeFront || store}
                                />
                              </div>
                            );
                          })
                      : createdTokens?.created_nft_tokens?.map((nft) => {
                          return (
                            <div
                              key={nft.id}
                              className={
                                nft?.visible
                                  ? 'place-self-center cursor-pointer relative'
                                  : 'hidden'
                              }
                            >
                              <NFTCard
                                edit={false}
                                nft={nft}
                                key={`${nft.token_id} + ${nft.token_address}`}
                                disableShowHide={disableShowHide}
                                setDisableShowHide={setDisableShowHide}
                                showHideNFTDetails={false}
                                isLanding={!isUndefined(storeFront)}
                                storeName={storeFront || store}
                              />
                            </div>
                          );
                        })}
                  </div>
                </TabPane>
              ) : null}
              {!isEmpty(nfts) ? (
                <TabPane tab="Owned" key={3}>
                  <NFTCardCollection
                    themesView={themesView}
                    disableShowHide={disableShowHide}
                    setDisableShowHide={setDisableShowHide}
                    storeName={storeFront || store}
                    fetchingOwnedNfts={fetchingOwnedNfts}
                    nft={nfts}
                    isLanding={!isUndefined(storeFront)}
                    lastCardRef={lastCardRef}
                    loading={loading}
                  />
                </TabPane>
              ) : null}
            </Tabs>
          </div>
        </div>
        <TwitterModal />
      </>
    );
  }
}
