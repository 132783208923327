import { EditFilled } from '@ant-design/icons';
import { Avatar, Upload } from 'antd';
import { useMemo } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import { useProfileEdit } from '../../../contexts/profile-edit.context';
import { useGetUserProfile } from '../../../hooks/get-user-profile.hook';
import ReactGA from 'react-ga4';

export function EditProfileImages() {
  const { avatarImage, coverImage, setAvatarImage, setCoverImage } =
    useProfileEdit();

  const avatarImageURL = useMemo(
    () => (avatarImage ? URL.createObjectURL(avatarImage) : null),
    [avatarImage]
  );

  const coverImageURL = useMemo(
    () => (coverImage ? URL.createObjectURL(coverImage) : null),
    [coverImage]
  );

  const { accountId } = useAuth();

  const { avatar_url, cover_url } = useGetUserProfile(accountId + '');

  return (
    <div className="relative w-full">
      <div className="relative">
        <img
          className={'w-full h-24 object-cover rounded-b-xl relative'}
          src={coverImageURL ?? cover_url}
          alt="nice cover"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = 'assets/cover.png';
          }}
        />
        <Upload
          beforeUpload={(file) => {
            ReactGA.gtag('event', 'Change Cover Picture', {
              event_category: 'Cover Image',
              event_label: 'Change cover image',
              items: [
                {
                  file: file,
                },
              ],
            });
            setCoverImage(file);
          }}
          showUploadList={false}
          maxCount={1}
        >
          <EditFilled className="absolute top-2 left-2 text-white text-xl hover:scale-125 transition-transform duration-200 cursor-pointer" />
        </Upload>
      </div>
      <div className={'absolute top-[40px] mx-auto left-0 right-0 text-center'}>
        <Avatar
          size={120}
          className={'bg-gray-50 border-solid border-3 border-white relative'}
          src={avatarImageURL ?? avatar_url}
        />
        <Upload
          showUploadList={false}
          beforeUpload={(file) => {
            ReactGA.gtag('event', 'Change Profile Picture', {
              event_category: 'Profile Image',
              event_label: 'Change Profile Image',
              items: [
                {
                  file: file,
                },
              ],
            });
            setAvatarImage(file);
          }}
          maxCount={1}
        >
          <EditFilled className="absolute top-2/4 left-0 right-0 text-white text-xl hover:scale-125 transition-transform duration-200 cursor-pointer" />
        </Upload>
      </div>
    </div>
  );
}
