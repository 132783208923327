import { useApolloClient } from '@apollo/client';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import constate from 'constate';
import { clone, get, isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useCreateStorageObject } from '../hooks/create-storage-object.hook';
import { useAuth } from './auth.context';
import { useClientSDK } from './sdk.context';

class CustomException extends Error {}

function useProfileEditContext() {
  const [formData, setFormData] = useState({
    username: '',
    bio: '',
    display_name: '',
  });
  const [userLinks, setUserLinks] = useState([]);
  const sdk = useClientSDK();
  const { accountId, setUsername } = useAuth();
  const [loading, setLoading] = useState(false);
  const [avatarImage, setAvatarImage] = useState<RcFile>();
  const [coverImage, setCoverImage] = useState<RcFile>();
  const { uploadFileToFolder } = useCreateStorageObject();
  const [userData, setUserData] = useState({
    username: '',
    bio: '',
    display_name: '',
  });
  const client = useApolloClient();
  const { push } = useRouter();

  const handleSave = async () => {
    setLoading(true);
    try {
      const userObject = clone(formData);

      userObject.username = userObject.username.toLowerCase();

      const usernameQuery = await sdk.getUserByUserName({
        username: userObject.username,
      });

      if (
        !isEmpty(usernameQuery.users) &&
        get(usernameQuery, 'users.0.id') !== accountId
      ) {
        throw new CustomException('Username is already taken');
      }

      if (avatarImage) {
        const { storage_object_id } = await uploadFileToFolder(
          'avatars',
          avatarImage
        );

        userObject['avatar_object_id'] = storage_object_id;
      }

      if (coverImage) {
        const { storage_object_id } = await uploadFileToFolder(
          'covers',
          coverImage
        );

        userObject['cover_object_id'] = storage_object_id;
      }

      await sdk.updateUserById({ id: accountId, object: userObject });

      if (userData.username !== userObject.username) {
        push(`/${userObject.username}`);
      }

      setUsername(userObject.username);

      await client.refetchQueries({
        include: ['getUserByUserName', 'getUserLinksByUserNameAndLocation'],
      });
    } catch (error) {
      if (error instanceof CustomException) {
        message.error(error.message);
      } else {
        message.error('Profile saving failed exception occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const resetEverything = () => {
    setFormData({
      username: '',
      bio: '',
      display_name: '',
    });

    setUserLinks([]);
    setAvatarImage(undefined);
    setCoverImage(undefined);
  };

  const loadingProfile = async () => {
    setLoading(true);
    try {
      const userQuery = await sdk.getUserById({ id: accountId });
      setUserData({
        username: userQuery.user?.username ?? '',
        bio: userQuery.user?.bio ?? '',
        display_name: userQuery.user?.display_name ?? '',
      });
      setFormData({
        username: userQuery.user?.username ?? '',
        bio: userQuery.user?.bio ?? '',
        display_name: userQuery.user?.display_name ?? '',
      });
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    setFormData,
    handleSave,
    userLinks,
    setUserLinks,
    loading,
    avatarImage,
    setAvatarImage,
    coverImage,
    setCoverImage,
    setLoading,
    resetEverything,
    loadingProfile,
  };
}

export const [ProfileEditProvider, useProfileEdit] = constate(
  useProfileEditContext
);
