import { Button } from 'antd';
import { useRouter } from 'next/router';
import { TwitterModal } from './twitter-modal';

export function NoStoreView() {
  const { push } = useRouter();

  return (
    <>
      <div className="h-[90vh] flex flex-col justify-center items-center text-gray-500 text-xl font-bold">
        Store not found! If this is your handle, click here to claim it now
        <Button
          type="primary"
          size="large"
          className="rounded-md w-40 mt-4"
          onClick={() => push('/login')}
        >
          Claim
        </Button>
      </div>
      <TwitterModal />
    </>
  );
}
