import { gql } from '@apollo/client';
import { get, intersectionWith, unionBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetUserNftsQuery } from '../components/nfts/user-nfts.generated';

export const useNft = ({ store }) => {
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const NFT_SUBSCRIPTION_DOCUMENT = gql`
    subscription getUserNfts(
      $username: String!
      $token_address: order_by = asc
      $limit: Int = 50
    ) {
      user_nft_tokens(
        where: { username: { _eq: $username } }
        limit: $limit
        order_by: { token_address: $token_address }
      ) {
        asset_org_url
        asset_thumbnail_url
        asset_type
        asset_url
        current_price
        description
        id
        marketplace
        name
        metadata
        owned_by
        owner_address
        sale_type
        symbol
        token_address
        token_id
        visible
        wallet_address
        order_type
        wallet_provider_id
      }
    }
  `;

  const {
    data: nftData,
    error,
    refetch,
    subscribeToMore,
    loading,
    networkStatus,
  } = useGetUserNftsQuery({
    variables: {
      username: store as string,
    },
    fetchPolicy: 'network-only',
    skip: !store,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    subscribeToMore({
      document: NFT_SUBSCRIPTION_DOCUMENT,
      variables: {
        username: store,
        limit: offset + 50,
      },
      updateQuery: (previousQueryResult, { subscriptionData }) => {
        if (!subscriptionData) {
          return previousQueryResult;
        }
        setHasMore(subscriptionData.data.user_nft_tokens.length > 0);
        const newNfts = subscriptionData.data.user_nft_tokens;
        return Object.assign({}, previousQueryResult, {
          __typename: 'user_nft_tokens',
          user_nft_tokens: intersectionWith(
            unionBy(
              [
                ...(previousQueryResult.user_nft_tokens || []),
                ...(subscriptionData.data.user_nft_tokens || []),
              ],
              'id'
            ),
            newNfts,
            (a, b) => a.id === b.id
          ),
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const observer = useRef<IntersectionObserver>();
  const lastCardRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && hasMore) {
          setOffset((prev) => {
            return prev + 50;
          });
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, loading]
  );

  return {
    data: get(nftData, 'user_nft_tokens', []),
    loading: loading || networkStatus === 1,
    hasMore,
    error: error,
    refetch,
    lastCardRef,
  };
};
