import { useBoolean } from 'ahooks';
import { Button, Modal, Typography } from 'antd';
import {
  ProfileEditProvider,
  useProfileEdit,
} from '../../../contexts/profile-edit.context';
import { AddButtonLinks } from './add-button-links';
import { AddRoundLinks } from './add-round-links';
import { EditProfileImages } from './edit-profile-images';
import { ProfileForm } from './profile-form';

function ProfileModalMain({ getStarted }: { getStarted?: boolean }) {
  const [visible, { toggle, setFalse }] = useBoolean(false);
  const { handleSave, resetEverything, loadingProfile, loading } =
    useProfileEdit();

  const handleClick = async () => {
    await loadingProfile();
    toggle();
  };

  return (
    <>
      {getStarted ? (
        <Button
          size="large"
          className="rounded-md w-44 lg:w-60 h-12"
          type="primary"
          onClick={handleClick}
        >
          Get Started
        </Button>
      ) : (
        <Button
          type="link"
          className="text-xl"
          loading={loading}
          onClick={handleClick}
        >
          Edit Profile
        </Button>
      )}
      <Modal
        destroyOnClose
        visible={visible}
        footer={null}
        onCancel={() => {
          if (!loading) setFalse();
        }}
        closable={false}
      >
        <div className="flex justify-between mb-4  items-center m-0 ">
          <Button
            type="ghost"
            disabled={loading}
            onClick={() => {
              resetEverything();
              setFalse();
            }}
          >
            Cancel
          </Button>
          <Typography.Text className="text-lg">My Profile</Typography.Text>
          <Button
            loading={loading}
            onClick={async () => {
              await handleSave();
              toggle();
            }}
            type="ghost"
          >
            Save
          </Button>
        </div>

        <div className="mb-20">
          <EditProfileImages />
        </div>
        <ProfileForm />
        <Typography.Text className="text-lg">Profile Links</Typography.Text>
        <div className="mb-4">
          <AddRoundLinks />
        </div>
        <div>
          <AddButtonLinks />
        </div>
      </Modal>
    </>
  );
}

export function ProfileModal({ getStarted }: { getStarted?: boolean }) {
  return (
    <ProfileEditProvider>
      <ProfileModalMain getStarted={getStarted} />
    </ProfileEditProvider>
  );
}
