import { useMemo } from 'react';
import { useGetUserByIdQuery } from '../graphql/users.generated';
import { getFileBasePath } from '../helpers/storage.helper';

export const useGetUserProfile = (accountId: string) => {
  const { data: userQuery } = useGetUserByIdQuery({
    variables: { id: accountId },
    skip: !accountId,
  });

  return useMemo(() => {
    return {
      avatar_url:
        getFileBasePath(userQuery?.user?.avatar_object?.storage_url) ??
        'assets/profile.png',
      cover_url:
        getFileBasePath(userQuery?.user?.cover_object?.storage_url) ??
        'assets/cover.png',
    };
  }, [userQuery]);
};
