import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ShowHideLogo } from '../icons';
import { NFTCard } from './nft-card';
import { GetUserNftsQuery } from './user-nfts.generated';

interface NFTCardCollectionProps {
  storeName?: string | undefined;
  disableShowHide: boolean;
  themesView?: boolean;
  fetchingOwnedNfts: boolean;
  setDisableShowHide: React.Dispatch<React.SetStateAction<boolean>>;
  nft: GetUserNftsQuery['user_nft_tokens'];
  isLanding?: boolean;
  lastCardRef: (node: HTMLDivElement) => void;
  loading: boolean;
}

export function NFTCardCollection({
  storeName,
  disableShowHide,
  themesView,
  setDisableShowHide,
  fetchingOwnedNfts,
  nft,
  isLanding = false,
  lastCardRef,
  loading,
}: NFTCardCollectionProps) {
  const [showNFTDetails, setShowNFTDetails] = useState<boolean>(isLanding);
  const [nfts, setNfts] = useState<GetUserNftsQuery['user_nft_tokens']>([]);

  useEffect(() => {
    if (themesView) {
      setNfts(nft?.slice(0, 4));
    } else {
      setNfts(nft);
    }
  }, [nft, themesView]);

  if (loading)
    return (
      <div className="w-full h-[80vh] flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  const edit = false;
  const ShowHideNFTDetailsButton = () => (
    <div className="w-full text-right">
      <ShowHideLogo
        className="cursor-pointer text-lg  xl:text-2xl"
        onClick={() => setShowNFTDetails(!showNFTDetails)}
      />
    </div>
  );
  return (
    <>
      {fetchingOwnedNfts && isEmpty(nfts) && (
        <div className="w-full h-[80vh] flex flex-col gap-5 justify-center items-center text-center text-lg">
          <span>Indexing NFTs: Please wait while we load the NFTs.</span>
          <Spin size="large" />
        </div>
      )}
      {/* {nfts.length > 0 && <ShowHideNFTDetailsButton />} */}
      <div
        className={
          themesView
            ? 'grid grid-cols-2 place-content-center container px-2'
            : 'my-5 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-5 lg:gap-10 place-content-center container px-2'
        }
      >
        {storeName &&
          nfts.map((nft, index) => {
            if (index === nfts.length - 10)
              return (
                <div
                  key={nft.id}
                  className={
                    nft?.visible || edit
                      ? 'place-self-center cursor-pointer relative'
                      : 'hidden'
                  }
                  ref={lastCardRef}
                >
                  <NFTCard
                    edit={edit}
                    themesView={themesView ? true : false}
                    nft={nft}
                    key={`${nft.token_id} + ${nft.token_address}`}
                    disableShowHide={disableShowHide}
                    setDisableShowHide={setDisableShowHide}
                    showHideNFTDetails={showNFTDetails}
                    isLanding={isLanding}
                    storeName={storeName}
                  />
                </div>
              );
            return (
              <div
                key={nft.id}
                className={
                  nft?.visible || edit
                    ? 'place-self-center cursor-pointer relative'
                    : 'hidden'
                }
              >
                <NFTCard
                  edit={edit}
                  themesView={themesView ? true : false}
                  nft={nft}
                  key={`${nft.token_id} + ${nft.token_address}`}
                  disableShowHide={disableShowHide}
                  setDisableShowHide={setDisableShowHide}
                  showHideNFTDetails={showNFTDetails}
                  isLanding={isLanding}
                  storeName={storeName}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}
