import { Avatar, Typography } from 'antd';
import { useMemo } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { useGetUserLinksByOwnerIdAndLocationQuery } from '../../graphql/user-links.generated';
import { useGetUserByUserNameQuery } from '../../graphql/users.generated';
import { getFileBasePath } from '../../helpers/storage.helper';
import { useStoreName } from '../../hooks/store-name.hook';
import { Enum_Display_Location_Enum } from '../nfts/user-nfts.generated';
import { ProfileModal } from '../settings/profile/profile-modal';
import { ViewRoundLinks } from './links/view-round-links';

export function ProfileContainer({ storeFront }: { storeFront?: string }) {
  const { accountId: id } = useAuth();

  const store = useStoreName();

  const { data: userQuery } = useGetUserByUserNameQuery({
    variables: { username: storeFront || store + '' },
    skip: !storeFront && !store,
  });

  const { avatar_url, cover_url, display_name, bio, accountId } =
    useMemo(() => {
      return {
        accountId: userQuery?.users[0]?.id,
        avatar_url:
          getFileBasePath(userQuery?.users[0]?.avatar_object?.storage_url) ??
          'assets/profile.png',
        cover_url:
          getFileBasePath(userQuery?.users[0]?.cover_object?.storage_url) ??
          'assets/cover.png',
        display_name: userQuery?.users[0]?.display_name,
        bio: userQuery?.users[0]?.bio,
      };
    }, [userQuery]);

  const { data: userLinks } = useGetUserLinksByOwnerIdAndLocationQuery({
    variables: {
      owned_by: accountId,
      display_location: Enum_Display_Location_Enum.ProfileCircleLinks,
    },
    skip: !accountId,
  });

  return (
    <>
      <div className="shadow-lg drop-shadow-xl w-full rounded-b-3xl pb-5 sub-header">
        <div className="relative w-full mb-20">
          <img
            className={'w-full h-36 md:h-48 object-cover rounded-b-xl'}
            src={cover_url}
            alt="nice cover"
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = '/defaults/images/cover.png';
            }}
          />
          <div
            className={
              'absolute top-[80px] md:top-[130px] mx-auto left-0 right-0 text-center'
            }
          >
            <Avatar
              size={120}
              className={'drop-shadow-lg rounded-full profile-border'}
              src={
                <img
                  src={avatar_url}
                  alt="nice avatar"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = '/defaults/images/profile.png';
                  }}
                />
              }
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          {display_name && (
            <div className="text-xl font-semibold uppercase text-center">
              {display_name}
            </div>
          )}
          {bio && <div className="text-lg mt-5 text-center">{bio}</div>}
        </div>
        <div className="flex flex-col justify-center items-center">
          <ViewRoundLinks links={userLinks?.user_links} />
        </div>
        {!bio && userLinks?.user_links.length === 0 && accountId === id && (
          <div className="flex flex-col justify-center items-center">
            <Typography className="text-xl my-5">Setup your profile</Typography>
            <ProfileModal getStarted={true} />
          </div>
        )}
      </div>
    </>
  );
}
