import { Enum_Display_Location_Enum } from '../graphql/account-provider.generated';
import { useGetUserLinksByOwnerIdAndLocationQuery } from '../graphql/user-links.generated';
import { useGetUserByUserNameQuery } from '../graphql/users.generated';

export const useGetUserLinksByStore = (store: string) => {
  const { data: storeUser } = useGetUserByUserNameQuery({
    variables: { username: store },
    skip: !store,
  });

  const { data: userLinks } = useGetUserLinksByOwnerIdAndLocationQuery({
    variables: {
      display_location: Enum_Display_Location_Enum.GeneralLinks,
      owned_by: storeUser?.users[0]?.id as string,
    },
    skip: !storeUser?.users[0]?.id,
  });

  return { userLinks };
};
