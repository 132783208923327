import { Image, message, Modal, Spin, Tabs, Tooltip, Upload } from 'antd';
import React, { useState } from 'react';
import { socialMediaIcons } from '../../../constants/icons.constant';
import { useCreateStorageObject } from '../../../hooks/create-storage-object.hook';

interface IconsModalProps {
  showIcons: boolean;
  setShowIcons: React.Dispatch<React.SetStateAction<boolean>>;
  photoUrl?: { storage_object_id: string; localFileURL: string };
  setPhotoUrl: React.Dispatch<
    React.SetStateAction<
      { storage_object_id: string; localFileURL: string } | undefined
    >
  >;
  setIcon: React.Dispatch<
    React.SetStateAction<{ name: string; component: JSX.Element } | undefined>
  >;
}

export function IconsModal({
  showIcons,
  setShowIcons,
  setIcon,
  photoUrl,
  setPhotoUrl,
}: IconsModalProps) {
  const { TabPane } = Tabs;

  const [loadingPreview, setLoadingPreview] = useState(false);

  const { uploadFileToFolder } = useCreateStorageObject();
  return (
    <Modal
      closable
      visible={showIcons}
      onCancel={() => {
        setPhotoUrl && setPhotoUrl(undefined);
        setIcon(undefined);
        setShowIcons(false);
      }}
      footer={null}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Default icons" key="1">
          <div className="grid grid-cols-6 gap-y-2">
            {socialMediaIcons.map((icon) => {
              const Icon = icon['component'];
              return (
                <Tooltip title={icon.name} key={icon.name}>
                  <Icon
                    className="cursor-pointer hover:scale-110 transition-transform ease-linear duration-200 icon"
                    onClick={() => {
                      message.success(`${icon.name} icon selected`);
                      setPhotoUrl && setPhotoUrl(undefined);
                      setIcon(icon);
                      setShowIcons(false);
                    }}
                  />
                </Tooltip>
              );
            })}
          </div>
        </TabPane>
        <TabPane tab="Upload icons" key="2">
          <Upload
            beforeUpload={async (file) => {
              setLoadingPreview(true);
              if (file.type.includes('image') && file.size < 10000000) {
                const { storage_object_id, localFileURL } =
                  await uploadFileToFolder(`icons`, file);
                setPhotoUrl({ storage_object_id, localFileURL });
              } else {
                message.error(
                  'Only images with size less than 10 MB are allowed'
                );
              }
              setIcon(undefined);
              setLoadingPreview(false);
            }}
            maxCount={1}
            className="rounded-full"
            showUploadList={false}
          >
            <div className="flex flex-col justify-center items-center gap-5">
              {photoUrl ? (
                <Image
                  src={photoUrl?.localFileURL}
                  className="w-full"
                  alt={photoUrl.storage_object_id}
                />
              ) : (
                loadingPreview && <Spin />
              )}
              <div
                className="text-base bg-[#6fced6] text-white p-2 rounded-md 
            hover:scale-110 transition-transform ease-linear duration-150 cursor-pointer"
              >
                Click to upload
              </div>
            </div>
          </Upload>
        </TabPane>
      </Tabs>
    </Modal>
  );
}
