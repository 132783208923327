import { GlobalOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Image } from 'antd';
import Link from 'next/link';
import { useState } from 'react';
import { socialMediaIconsObject } from '../../../constants/icons.constant';
import {
  GetUserLinksByOwnerIdAndLocationQuery,
  useDeleteUserLinkByIdMutation,
} from '../../../graphql/user-links.generated';
import { getFileBasePath } from '../../../helpers/storage.helper';
import { Enum_Display_Location_Enum } from '../../nfts/user-nfts.generated';
import { CreateUserLinks } from '../../settings/profile/create-user-links';

interface ViewButtonLinksProps {
  links: GetUserLinksByOwnerIdAndLocationQuery['user_links'] | undefined;
  editMode?: boolean;
}

export function ViewButtonLinks({ links, editMode }: ViewButtonLinksProps) {
  const [showAddLink, setShowAddLink] = useState<boolean>(false);
  const [linksToEdit, setLinksToEdit] = useState<
    GetUserLinksByOwnerIdAndLocationQuery['user_links'][0] | undefined
  >();
  const [deleteUserLinkByUserId] = useDeleteUserLinkByIdMutation({
    refetchQueries: ['getUserLinksByOwnerIdAndLocation'],
  });

  const LinkComponent = ({ link, imagePath, iconName, iconType }) => {
    const Icon = socialMediaIconsObject[iconName ?? 'bookmark'];
    return (
      <div
        className="text-base border border-solid px-2 cursor-pointer justify-start rounded-md flex gap-2 w-72 md:w-96 h-10 items-center
                hover:scale-105 transition-transform ease-linear link"
      >
        {iconType === 'image' ? (
          imagePath ? (
            <Image
              src={imagePath}
              preview={false}
              className="w-5 h-5 rounded-full object-cover"
              alt={link?.link_name}
            />
          ) : (
            <GlobalOutlined className=" pt-1.5 text-2xl rounded-full object-cover icon" />
          )
        ) : (
          <Icon className="w-5 h-5 rounded-full object-cover icon" />
        )}
        {link?.link_name?.length > 20
          ? link?.link_name?.substring(0, 20) + '...'
          : link?.link_name}
      </div>
    );
  };

  return (
    <div className="grid gap-5 justify-items-center w-full">
      {links?.map((link, key) => {
        const iconType = link?.icon_object ? 'image' : 'icon';

        const imagePath = link?.icon_object?.storage_url
          ? getFileBasePath(link?.icon_object?.storage_url)
          : undefined;

        const iconName =
          link?.icon_preset as keyof typeof socialMediaIconsObject;
        return (
          <div key={key} className="relative">
            {editMode ? (
              <div
                onClick={() => {
                  setLinksToEdit(link);
                  setShowAddLink(true);
                }}
              >
                <LinkComponent
                  link={link}
                  imagePath={imagePath}
                  iconName={iconName}
                  iconType={iconType}
                />
              </div>
            ) : (
              <Link href={link?.link_url} passHref>
                <a target={'_blank'} rel="noopener noreferrer">
                  <LinkComponent
                    link={link}
                    imagePath={imagePath}
                    iconName={iconName}
                    iconType={iconType}
                  />
                </a>
              </Link>
            )}

            {editMode && (
              <div className="flex gap-3 items-center absolute -top-3 right-0">
                <MinusCircleFilled
                  onClick={() => {
                    deleteUserLinkByUserId({ variables: { id: link.id } });
                  }}
                  className="cursor-pointer text-xl icon"
                />
              </div>
            )}
          </div>
        );
      })}
      <CreateUserLinks
        display_location={Enum_Display_Location_Enum.GeneralLinks}
        showAddLink={showAddLink}
        setShowAddLink={setShowAddLink}
        linkToEdit={linksToEdit}
        setLinkToEdit={setLinksToEdit}
      />
    </div>
  );
}
